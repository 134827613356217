import React from 'react';
import Link from 'next/link';
import { ButtonWrapper } from '../../../pagestyles/components/common/ButtonWrapper';

type ButtonProps = {
  children?: React.ReactNode;
  id?: string;
  label?: string;
  type?: 'primary' | 'secondary' | 'tertiary' | 'alternative' | 'links';
  size?: 'small' | 'medium' | 'large';
  icon?: string;
  iconPosition?: 'left' | 'right';
  active?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<any>) => void;
  className?: string;
  subClassName?: string;
  style?: React.CSSProperties;
  width?: string;
  href?: string;
  target?: string;
  rel?: string;
  dataTestId?: string;
};

const Button = ({
  children,
  id,
  label,
  type,
  size,
  icon,
  iconPosition,
  active,
  disabled,
  onClick,
  className,
  style,
  width,
  href,
  target,
  rel,
  subClassName,
  dataTestId,
}: ButtonProps): JSX.Element => {
  const renderCustomCss = (child: React.ReactNode) => {
    const wrapProps = {
      ...(id && { id }),
      ...(className !== '' && { className }),
      ...(style && { style }),
    };
    return (
      <ButtonWrapper style={{ ...(type === 'links' && !className?.includes('btn') && { display: 'inline-block' }) }} {...wrapProps}>
        {child}
      </ButtonWrapper>
    );
  };

  const isATag = target || rel;

  const linkDecorator = (child: React.ReactNode) => {
    if (href && !isATag) {
      return <Link href={href}>{child}</Link>;
    }
    return child;
  };

  const aTagDecorator = (child: React.ReactNode) => {
    if (isATag) {
      const aProps = {
        ...(target && { target }),
        ...(rel && { rel }),
        href: href || '/#',
      };
      return <a {...aProps}>{child}</a>;
    }
    return child;
  };
  const getLabel = () => {
    if (label) {
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: label }} />;
    }
    return children;
  };

  const renderButton = (child: React.ReactNode) => {
    const iconClass = icon ? 'has-icon' : 'no-icon';
    const linksIconCss = type === 'links' ? iconClass : '';
    const classButton = `button ${subClassName} ${type} ${size}-v1 ${icon ? iconPosition : ''} ${linksIconCss} ${active ? 'active' : ''} ${disabled ? 'disable' : ''}`;
    const iconStyle = icon ? { backgroundImage: `url(${icon})` } : {};
    const widthStyle = width ? { width } : {};
    const btnProps = {
      className: classButton,
      style: { ...iconStyle, ...widthStyle },
      onClick,
      ...(dataTestId && { 'data-testid': dataTestId }),
    };
    return <div {...btnProps}>{child}</div>;
  };

  return <>{renderCustomCss(linkDecorator(renderButton(aTagDecorator(getLabel()))))}</>;
};

Button.defaultProps = {
  id: undefined,
  children: undefined,
  label: undefined,
  type: 'primary',
  size: 'medium',
  icon: undefined,
  iconPosition: 'right',
  active: false,
  onClick: () => {},
  className: '',
  subClassName: '',
  style: undefined,
  disabled: false,
  width: '',
  href: undefined,
  target: undefined,
  rel: undefined,
  dataTestId: '',
};

export default Button;
