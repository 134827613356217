/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  .button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.02em;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 500px;
    cursor: pointer;
    background-repeat: no-repeat;
  }

  .button.disable {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

	.small-v1 {
		padding: 9px 15px;
		font-size: 14px;
		background-size: 16px;
	}

	.small-v1.right {
		padding-right: calc(15px + 16px + 8px);
		background-position: calc(100% - 15px) center;
	}

	.small-v1.left {
		padding-left: calc(15px + 16px + 8px);
		background-position: 15px center;
	}

  .medium-v1 {
    padding: 12px 32px;
    font-size: 16px;
    background-size: 20px;
  }

  .medium-v1.right {
    padding-right: calc(24px + 20px + 8px);
    background-position: calc(100% - 24px) center;
  }

  .medium-v1.left {
    padding-left: calc(24px + 20px + 8px);
    background-position: 24px center;
  }

  .large-v1 {
    padding: 16px 48px;
    font-size: 18px;
    background-size: 24px;
  }

  .large-v1.right {
    padding-right: calc(48px + 24px + 8px);
    background-position: calc(100% - 48px) center;
  }

  .large-v1.left {
    padding-left: calc(48px + 24px + 8px);
    background-position: 48px center;
  }

  .primary {
    background-color: #f5785a;
    color: #ffffff;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
  }

  .primary:hover {
    background-color: #ef5935;
  }

  .primary.active,
  .primary:active {
    background-color: #ef5935;
    outline: #ffae9a solid 2px;
  }

  .secondary {
    background-color: #ffe4dd;
    color: #ee6746;
  }

  .secondary:hover {
    background-color: #ffc9bc;
  }

  .secondary.active,
  .secondary:active {
    background-color: #ffc9bc;
    outline: #ffe4dd solid 2px;
  }

  .tertiary {
    border: 1px solid #dcd2bd;
    filter: drop-shadow(0px 1px 7px rgba(0, 0, 0, 0.05));
    color: #172134;
  }

  .tertiary:hover {
    border: 1px solid #172134;
  }

  .tertiary.active,
  .tertiary:active {
    border: 1px solid #172134;
    outline: #d1d3d6 solid 2px;
  }

  .alternative {
    background-color: #637c5a;
    color: #ffffff;
  }

  .alternative:hover {
    background-color: #435c3a;
  }

  .alternative.active,
  .alternative:active {
    background-color: #435c3a;
    outline: #bed0a2 solid 2px;
  }

  .links {
    display: inline-block;
    text-decoration: none;
    color: #172134;
    border-radius: 0;
    padding: 0px 0px 1px;
    gap: 4px;
  }

  a {
    color: #172134;
  }
  a:hover {
    color: #172134;
  }

  .links.no-icon {
    background-image: linear-gradient(#172134, #172134);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 100% 1px;
  }

  .links.has-icon {
    border-bottom: 0.5px solid #172134;
  }
  .links.right {
    background-position: right center;
  }

  .links.medium-v1.right {
    padding-right: calc(20px + 4px);
  }

  .links.medium-v1.left {
    padding-left: calc(20px + 4px);
  }

  .links.large-v1.right {
    padding-right: calc(24px + 4px);
  }

  .links.large-v1.left {
    padding-left: calc(24px + 4px);
  }

  .links.left {
    padding-left: 0px;
    background-position: left center;
  }

  .links.no-icon:hover,
  .links.no-icon:active,
  .links.no-icon.active {
    background-size: 100% 2px;
  }

  .links.has-icon:hover,
  .links.has-icon:active,
  .links.has-icon.active {
    border-bottom: 2px solid #172134;
  }

  .btn-bottom-bar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 999;
    display: block;
    width: 100%;
  }
`;
