import { useRouter } from 'next/router';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSurvey } from '../containers/SurveyContainer';
import { ModalBody, ModalFooter } from '../pagestyles/components/ModalUnfinishedQuiz';
import Button from './common/button/Button';

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  hideButtonRecommendation: boolean;
};

const ModalRedoQuiz: React.FC<Props> = ({ showModal, setShowModal, hideButtonRecommendation }: Props) => {
  const router = useRouter();
  const { asPath } = router;
  const setLocalCurrentQuestion = useSurvey((container) => container.setLocalCurrentQuestion);
  const setLocalQuestions = useSurvey((container) => container.setLocalQuestions);
  const setResponses = useSurvey((container) => container.setResponses);
  const resetQuiz = () => {
    setLocalCurrentQuestion.reset();
    setLocalQuestions.reset();
    setResponses.reset();
    if (asPath.includes('/reactivation')) {
      router.push({
        pathname: '/survey',
        query: { backToReactivationScreen: true },
      });
    } else {
      window.location.href = '/survey';
    }
  };
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <ModalBody className='redo-quiz-modal'>
        <span className='close-btn' onClick={() => setShowModal(false)} aria-hidden>
          &times;
        </span>
        <div className='modal-body text-center custom-title-quiz'>Do you wish to start a new quiz? Your previous answers won&apos;t be saved.</div>
        <ModalFooter>
          <Button dataTestId="startQuizAgain" type='alternative' style={{ minWidth: `${hideButtonRecommendation ? '200px' : '280px'}` }} onClick={() => resetQuiz()}>
            Start again
          </Button>
          {!hideButtonRecommendation && (
            <Button
              dataTestId="takeRecommendation"
              type='links'
              onClick={() => {
                setShowModal(false);
                router.push('/recommendation');
              }}
            >
              Take me to my recommendation
            </Button>
          )}
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};
export default ModalRedoQuiz;
